<template>
  <div>

    <v-expansion-panels

    v-model="panel"
    >


      <v-expansion-panel

      >
        <v-expansion-panel-header>
          Tour Daten
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
              class="mx-auto justify-center text-center"
              width="600"
              style="padding-top: 30px;"
              elevation-10
          >
            <v-card-title primary-title class="justify-center">
              <div>
                <h3 class="headline text--accent-2">{{ this.title }}</h3>
              </div>
            </v-card-title>

            <v-form
                autocomplete="off"
            >

              <DialogDate
                  v-bind:label="'Geplantes Datum'"
                  v-bind:initial_date="date"
                  v-bind:clearable="true"
                  v-model="date"
                  :key="date"

              ></DialogDate>

              <div
              v-if="reRenderer"
              >

              <CustomSelect
                  v-model="driver"
                  v-bind:table="'driver'"
                  v-bind:field_text="'name'"
                  v-bind:field_value="'id'"
                  v-bind:label="'Fahrer'"
                  v-bind:selected="driver_id"

                  style="
        display: table;
        margin: 0 auto;
        width: 350px;"
              ></CustomSelect>

              <CustomSelect
                  v-model="car"
                  v-bind:table="'cars'"
                  v-bind:field_text="'description'"
                  v-bind:field_value="'id'"
                  v-bind:label="'Fahrzeug'"
                  v-bind:selected="car_id"

                  style="
        display: table;
        margin: 0 auto;
        width: 350px;"
              ></CustomSelect>

              </div>


              <v-select
                  v-model="start_point"
                  outlined
                  label="Abfahrt von"
                  :items="items_start_point"

                  style="
        display: table;
        margin: 0 auto;
        width: 350px;"
              ></v-select>

              <v-select
                  v-model="end_point"
                  outlined
                  label="Rückfahrt zu"
                  :items="items_end_point"
                  @change="changeEndPoint()"
                  style="
        display: table;
        margin: 0 auto;
        width: 350px;"
              ></v-select>



              <v-text-field
                  v-model="parking_address"
                  outlined
                  label="Sammelstelle"
                  value=""
                  style="
      display: table;
      margin: 0 auto;
      width: 350px;"
              ></v-text-field>


              <v-textarea
                  v-model="note"
                  outlined
                  label="Bemerkungen"
                  value=""
                  style="
      display: table;
      margin: 0 auto;
      width: 350px;"
              ></v-textarea>



              <v-col
                  sm="12"
                  md="12"
                  class="text-right"
              >

                <v-btn
                    color="red"
                    elevation="2"
                    outlined
                    style="margin-right: 40px"
                    @click="btnCancel"
                >Abbrechen</v-btn>


                <v-btn
                    color="green"
                    elevation="2"
                    outlined
                    @click="saveData"
                >Speichern</v-btn>


              </v-col>

            </v-form>

            <v-spacer />


          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel

        >
        <v-expansion-panel-header>
          Aufträge {{num_of_pos}}
        </v-expansion-panel-header>
        <v-expansion-panel-content
        >

          <v-card
style="padding-bottom: 50px;"
          >
            <v-toolbar
                color="#383083"
            >

              <v-toolbar-title
              style="color: white;"
              >Ausgewählte Aufträge - Gesamt Anzahl: <span style="color: #e91e63"><b>{{qty}}</b></span>
              </v-toolbar-title>



            </v-toolbar>

                <List
                    v-bind:headers="headers_selected"
                    v-bind:formview="formview"
                    v-bind:table="'task'"

                    v-bind:addbutton="false"
                    v-bind:default_limit="'500'"
                    v-bind:default_sort="'tour_position'"
                    v-bind:default_sort_typ="'ASC'"
                    v-bind:extension="'(temp_tour_id = \'' + temp_tour_id + '\' OR tour_id = \'' + tour_id + '\') AND (pickup_date IS NULL)'"

                    v-bind:temp_tour_id="temp_tour_id"
                    @reload="reload_table_unassigned"
                    :key="key_table_selected"
                ></List>

          </v-card>

<div
style="padding: 20px;"
></div>


          <v-switch style="margin: 0px;" v-model="show_planed_tasks" label="Bereits geplante Aufträge einblenden"></v-switch>
         <!-- <v-switch style="margin: 0px;" v-model="show_collection_points" label="Zeige Sammelstellen"></v-switch> -->
<div
style="margin: 10px;"
>
          <div
          style="margin-bottom: 5px;"
          >Fahrtzeit: <b>{{calc_driving_time}}</b> Wegstrecke: <b>{{calc_driving_distance }}</b> in Km</div>

          <div>

            <v-btn
                @click="calcRouteDirectionsService()"
                outlined
            ><v-icon>mdi-map-marker-distance</v-icon> Route berechnen</v-btn>

            <v-btn
                @click="calcRoute()"
                style="margin-left: 10px;"
                outlined
                disabled
            ><v-icon>mdi-auto-fix</v-icon> Route Optimieren</v-btn>

          </div>
</div>


          <v-card

          >
            <v-toolbar
                color="pink"
                dark
                flat
            >

              <v-toolbar-title>Unzugeordnete Aufträge</v-toolbar-title>

              <template v-slot:extension>
                <v-tabs
                    v-model="tab"
                    align-with-title
                >
                  <v-tabs-slider color="white"></v-tabs-slider>

                  <v-tab
                      v-for="item in items"
                      :key="item"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>

            <v-tabs-items v-model="tab">


              <v-tab-item
                  style="padding-bottom: 50px;"
              >

                <List
                    v-bind:headers="headers_add"
                    v-bind:formview="formview"
                    v-bind:table="'task'"

                    v-bind:addbutton="false"
                    v-bind:default_limit="'500'"
                    v-bind:default_sort="'prio'"
                    v-bind:default_sort_typ="'DESC'"
                    v-bind:extension="'(tour_id = \'\' OR tour_id IS NULL) AND (temp_tour_id <> \'' + temp_tour_id + '\' OR temp_tour_id IS NULL)'"

                    v-bind:temp_tour_id="temp_tour_id"
                    @reload="reload_table_selected"
                    :key="key_table_unassigned"
                ></List>

              </v-tab-item>

              <v-tab-item>

                <gmap-map
                    :center='center'
                    :zoom='10'
                    map-type-id="roadmap"
                    ref="myMapRef"
                    :options="options"
                    style='width:100%;  height: 400px;'
                >

                  <!-- HOME ICON -->
                  <gmap-custom-marker
                      :marker="{ lat: 50.6470474, lng:8.2283223 }"
                      alignment="center"
                  >
                    <img width="50px" height="50px" :src="require('@/assets/images/home.png')" />
                  </gmap-custom-marker>

                  <!-- ABHOLPUNKTE -->
                  <gmap-custom-marker
                      v-for="(marker, index) in markers"
                      :key="index"
                      :marker="{ lat: marker.lat, lng: marker.lng }"
                      :offsetY="marker.eta ? 20 : 0"
                      alignment="center"
                      @click.native="clickMarker(marker.id, marker.selected, marker.shape)"
                  >

                    <img :src="require('@/assets/images/' + marker.shape + '_' + marker.color + marker.sprinter + '.png')" />
                    <span
                    style="
                    position: absolute;
                    top: 15px;
                    left: 20px;
                    "
                    ><h2>{{marker.label}}</h2></span>

                    <span
                        style="
                    position: absolute;
                    color: red;
                    background-color: white;
                    top: 40px;
                    left: 20px;
                    "
                    ><h2>{{marker.label_debug}}</h2></span>


                    <div
                        v-if="marker.color == 'pink' && marker.prio =='1'"
                    style="
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 8px;
                    height: 8px;
                    background-color: green;
                    border-radius: 50%;
                    display: inline-block;
                    "
                    >

                    </div>

                    <div
                        v-if="marker.color == 'pink' && marker.prio =='2'"
                        style="
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 8px;
                    height: 8px;
                    background-color: orange;
                    border-radius: 50%;
                    display: inline-block;
                    "
                    >

                    </div>

                    <div
                        v-if="marker.color == 'pink' && marker.prio =='3'"
                        style="
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 8px;
                    height: 8px;
                    background-color: red;
                    border-radius: 50%;
                    display: inline-block;
                    "
                    >

                    </div>


                  </gmap-custom-marker>


<!--
                <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m"
                    @click="center=m"
                    :icon="markerOptions"
                ></gmap-marker>
-->
                </gmap-map>

              </v-tab-item>




                <v-tab-item
                    style="padding-bottom: 50px;"
                >


                <v-card
                    class="mx-auto justify-center text-center"
                    width="600px"
                    height="300px"
                    style="
                           margin: 30px;
"
                    elevation-10
                >
                  <v-card-title>PLZ Bereich auswählen:</v-card-title>

                  <CustomSelect
                      v-model="group_tour"
                      v-bind:table="'group_tour'"
                      v-bind:field_text="'name'"
                      v-bind:field_value="'id'"
                      v-bind:label="'PLZ Bereich'"
                      v-bind:selected="group_tour_id"

                      style="
        display: table;
        margin: 0 auto;
        width: 350px;"
                  ></CustomSelect>

                  <v-btn
                      @click="selectByZipcode"
                  style=""
                  >Hinzufügen</v-btn>

                </v-card>

              </v-tab-item>



              <v-tab-item
                  style="padding-bottom: 50px;"
              >


                <v-card
                    class="mx-auto justify-center text-center"
                    width="600px"
                    height="300px"
                    style="
                           margin: 30px;
"
                    elevation-10
                >
                  <v-card-title></v-card-title>


              <v-btn
              outlined
              @click="selectFolgeauftraege()"
              >FOLGEAUFTRÄGE HINZUFÜGEN</v-btn>

                </v-card>

              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>





    <v-dialog
        v-model="loading_dialog"

        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Bitte warten...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>



  </div>




</template>

<script>
// import Vue from "vue";

import CustomSelect from "@/components/CustomSelect";
import DialogDate from "@/components/DialogDate";
import List from "@/components/ListDefault";

import {gmapApi} from 'vue2-google-maps'
import GmapCustomMarker from 'vue2-gmap-custom-marker';



export default {
  name: "FormTour",

  components:{

    CustomSelect,
    DialogDate,
    List,
    GmapCustomMarker,

  },


  mounted() {

    this.intervalCheckRefs = setInterval(this.checkRefs, 1000);

  },

  async created() {

    localStorage.map_customcontrols = "false"

  // https://developers.google.com/maps/documentation/routes_preferred/waypoint_optimization_proxy_api


  // https://www.digitalocean.com/community/tutorials/vuejs-vue-google-maps
  // https://stackoverflow.com/questions/51800404/custom-marker-for-vue2-google-maps
  // https://bestofvue.com/repo/eregnier-vue2-gmap-custom-marker-vuejs-map


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }else{

      var min = 100000000000000000000
      var max = 999999999999999999999
      this.temp_tour_id = (Math.round(Math.random() * (max - min)) + min).toString();

      // console.log("this.temp_tour_id: " + this.temp_tour_id)

      this.SelectArticle = true

    }


    await this.getSammelstellen()

    await this.setMarkerPoints()

  },

  data () {
    return {

      title: 'Tour',
      mainview: 'ListTour',
      formview: '',
      table: 'tour',

      intervalCheckRefs: null,

      markers: [],
      options: {},

      parking_id: '',
      parking_address: '',
      parking_lat: '',
      parking_lng: '',

      mapMarker: null, //require('~/assets/images/layout/map-marker.png'),

      markerOptions: {
        url: this.mapMarker,
        size: {width: 60, height: 90, f: 'px', b: 'px',},
        scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      },

      center: { lat: 50.64661, lng: 8.22819 },

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',

      reRenderer: true,

      timeout: 2500,

      loading_dialog: false,

      SelectArticle: false,
      SelectCustomer: false,

      controlUIGlobal: null,

      key_table_selected: '0',
      key_table_unassigned: '0',

      group_tour: '',
      group_tour_id: '',
      group_tour_zipcode: '',

      panel: 0,

      driver: '',
      driver_id: '',
      driver_name: '',

      car: '',
      car_id: '',
      car_description: '',
      car_capacity: '',

      id: '',
      tour_id: '0',
      temp_tour_id: '',

      directionsDisplay: null,

      start_point: 'Bahnhofstraße 3, 35759 Driedorf',        // Startpunkt --> Home oder Abholpunkt 1
      end_point: 'Bahnhofstraße 3, 35759 Driedorf',          // Endpunkt --> Home oder Abholpunkt Letzter '' <<--- bleibt leer

      items_start_point: ['Bahnhofstraße 3, 35759 Driedorf', 'Abholpunkt 1'],
      items_end_point: ['Letzter Abholpunkt', 'Bahnhofstraße 3, 35759 Driedorf'],

      calc_driving_time: 0,           // Kalkulierte Fahrtzeit
      calc_driving_distance: 0,       // Kalkulierte Wegstrecke

      waypoints: [],                  // Wegpunkte {id, lat, lng, adresse)

      show_planed_tasks: false,       // Zeigt bereits geplante Aufträge
      show_collection_points: true,   // Zeigt bereits geplante Aufträge

      note: '',
      date: '',

      qty: 0,
      article: '',
      num_of_pos: 0,

      tab: null,
      items: [
        'Liste', 'Karte', 'PLZ Schnellauswahl', 'Folgeaufträge'
      ],


    }
  },

methods:{

  /**
   * Prüfen ob der Endpunkt eine Sammelstelle ist
   *
   * @returns {Promise<void>}
   */

  async changeEndPoint(){

    if (this.end_point != "Letzter Abholpunkt" && this.end_point != "Bahnhofstraße 3, 35759 Driedorf"){

      this.parking_address = this.end_point

    }else{

      this.parking_id = ""
      this.parking_address = ""

    }



  },



    async getSammelstellen(){

      var param = {
        table: "parking",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // console.log("Sammelstellen")
      // console.log(result)

      for (var i1 = 0; i1 < result.length; i1++) {
        this.items_start_point.push(result[i1]["street"] + ", " + result[i1]["zipcode"] + " " + result[i1]["city"])
        this.items_end_point.push(result[i1]["street"] + ", " + result[i1]["zipcode"] + " " + result[i1]["city"])
      }


    },


    calcRoute(){

      // https://jsfiddle.net/gh/get/library/pure/googlemaps/js-samples/tree/master/dist/samples/distance-matrix/jsfiddle

      // console.log("calcRoute")

      // AIzaSyCZSbq7QfOxoasTS2MEICg9l9ZeBrgdaG4

      this.$gmapApiPromiseLazy().then(() => {
        //your code here
        //E.g. for directions
        // var directionsService = new this.google.maps.DirectionsService();
        var directionsService = new this.google.maps.routing.v2.ComputeRoutesRequest();

        function compare( a, b ) {
          if ( a.id < b.id ){
            return -1;
          }
          if ( a.id > b.id ){
            return 1;
          }
          return 0;
        }

        // Wegpunkte sortieren
        this.waypoints = this.waypoints.sort( compare );


        if( this.waypoints.length == 0){
          this.calc_driving_time = 0
          this.calc_driving_distance = 0
          return
        }


        var destinations = [];

        // console.log("TESTTEST")
        // console.log(this.start_point.name)

        // Homepoint wird gesetzt
        var origin1 = [];
        origin1.push({ lat: 50.64661, lng: 8.22819 })

        var last_lat, last_lng

            for (let i1 = 0; i1 < this.waypoints.length; i1++) {

    if(i1 == 0){

             if(this.start_point != "Home"){
                origin1 = []
                origin1 = [{lat: Number(this.waypoints[i1]["lat"]), lng: Number(this.waypoints[i1]["lng"])}]
              }else{
                destinations.push({lat: Number(this.waypoints[i1]["lat"]), lng: Number(this.waypoints[i1]["lng"])})
             }

    }else{

        origin1.push({lat: Number(this.waypoints[i1-1]["lat"]), lng: Number(this.waypoints[i1-1]["lng"])})
        destinations.push({lat: Number(this.waypoints[i1]["lat"]), lng: Number(this.waypoints[i1]["lng"])})

        last_lat  = Number(this.waypoints[i1]["lat"])
        last_lng  = Number(this.waypoints[i1]["lng"])

    }
            }

            if(this.end_point == "Home"){
              origin1.push({ lat: last_lat, lng: last_lng })
              destinations.push({ lat: 50.64661, lng: 8.22819 })
            }

            // console.log("destinations")
            // console.log(destinations)

        var request = {
          origins: origin1,
          // destinations: [destinationA, destinationB],
          destinations: destinations,

          travelMode: this.google.maps.TravelMode.DRIVING,
          unitSystem: this.google.maps.UnitSystem.METRIC,
          optimizeWaypoints: true,
          avoidHighways: false,
          avoidTolls: false,
        };

        directionsService(request).then((response) => {

          console.log("response - distanceMatrixService")
          console.log(response)

          /*

          if (response["rows"][0]["elements"].length > 0){

          var drive_time = 0
          var drive_distance = 0

            for (var i1 = 0; i1 < response["rows"][0]["elements"].length; i1++) {

              drive_time = drive_time + Number(response["rows"][0]["elements"][i1]["duration"]["value"])
              drive_distance = drive_distance + Number(response["rows"][0]["elements"][i1]["distance"]["value"])

            }

          }


          this.calcDrivingTime =  this.$moment().startOf("day").seconds(drive_time).format("HH:mm:ss")
          this.calcDrivingDistance = Number(drive_distance) / 1000

          this.calcDrivingDistance = this.calcDrivingDistance.toFixed(2)

          console.log("Fahrtzeit: " + drive_time + " in Sekunden")
          console.log("Fahrtzeit: " + this.calcDrivingTime + " ")
          console.log("Wegstrecke: " + drive_distance + " in Meter")
      */


        });



      });



/*
      const service = new gmapApi.google.maps.DistanceMatrixService();

      // const origin1 = { lat: 55.93, lng: -3.118 };
      const origin1 = "Eningen";
      const destinationA = "Reutlingen";
      const destinationB = "Betzingen";
      const request = {
        origins: [origin1],
        destinations: [destinationA, destinationB],
        travelMode: gmapApi.google.maps.TravelMode.DRIVING,
        unitSystem: gmapApi.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };

      service.getDistanceMatrix(request).then((response) => {
        // put response

        console.log("response")
        console.log(response)

      });

*/


    },

    calcRouteDirectionsService(){

    // https://jsfiddle.net/gh/get/library/pure/googlemaps/js-samples/tree/master/dist/samples/distance-matrix/jsfiddle

    console.log("calcRouteDirectionsService")

    if(this.directionsDisplay == null){
      this.directionsDisplay = new this.google.maps.DirectionsRenderer({suppressMarkers: true, preserveViewport: true});

      if(this.$refs.myMapRef === undefined){
        alert("Bitte Karte öffnen")
        return
      }

      this.directionsDisplay.setMap(this.$refs.myMapRef.$mapObject);
    }


    this.$gmapApiPromiseLazy().then(() => {
      //your code here
      //E.g. for directions
      // var directionsService = new this.google.maps.DirectionsService();
      // var distanceMatrixService = new this.google.maps.DistanceMatrixService();
      var directionsService = new this.google.maps.DirectionsService();

      function compare( a, b ) {
        if ( a.id < b.id ){
          return -1;
        }
        if ( a.id > b.id ){
          return 1;
        }
        return 0;
      }

      // Wegpunkte sortieren
      this.waypoints = this.waypoints.sort( compare );


      if( this.waypoints.length == 0){
        this.calc_driving_time = 0
        this.calc_driving_distance = 0

        this.directionsDisplay.setMap(null);
        this.directionsDisplay = null
        return
      }


      var destination = "";
      var waypoints = [];
      var origin = ""; // Home


      // origin = [{ lat: 50.64661, lng: 8.22819 }]


      // origin = "Bahnhofstraße 3, 35759 Driedorf"
      origin = this.start_point

     // var last_lat, last_lng

      for (let i1 = 0; i1 < this.waypoints.length; i1++) {
        if(i1 == 0){
          if(this.start_point == "Abholpunkt 1"){
            origin =  this.waypoints[i1]["address"]
          }else{

            waypoints.push({
              location: this.waypoints[i1]["address"],
              stopover: false
            });

          }
        }else{

          waypoints.push({
            location: this.waypoints[i1]["address"],
            stopover: false
          });

         // last_lat  = Number(this.waypoints[i1]["lat"])
         // last_lng  = Number(this.waypoints[i1]["lng"])

        }

       // destinations.push(this.waypoints[i1]["address"])

        if(this.parking_address != ""){
          // Letzte Adresse
          destination = this.parking_address
        }else{

          if(this.end_point != "Letzter Abholpunkt"){
            destination = this.end_point
          }else{
            // Letzte Adresse
            destination = this.waypoints[i1]["address"]
          }

        }

      }


      console.log("destination")
      console.log(destination)

      console.log("origin")
      console.log(origin)

      var request = {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        provideRouteAlternatives: false,
        // preserveViewport: true,
        travelMode: this.google.maps.TravelMode.DRIVING
      };

      console.log("request")
      console.log(request)

      directionsService.route(request).then((response) => {

        console.log("response - directionsService")
        console.log(response)

        if(waypoints.length != 0){
          this.directionsDisplay.setDirections(response);
        }else{
          return
        }


        /*
        if (status == this.google.maps.DirectionsStatus.OK) {
          this.directionsDisplay.setDirections(response);
        }
        */

        var total_distance = 0.00;
        for (var i1=0; i1<response.routes[0].legs.length; i1++) {
          total_distance += response.routes[0].legs[i1].distance.value;
        }

        var total_duration = 0;
        for (var i2=0; i2<response.routes[0].legs.length; i2++) {
          total_duration += response.routes[0].legs[i2].duration.value;
        }



        this.calc_driving_distance = Number(total_distance) / 1000;
        this.calc_driving_distance = this.calc_driving_distance.toFixed(2)

        this.calc_driving_time =  this.$moment().startOf("day").seconds(total_duration).format("HH:mm:ss")

        /*
                if (response["rows"][0]["elements"].length > 0){

                  var drive_time = 0
                  var drive_distance = 0

                  for (var i1 = 0; i1 < response["rows"][0]["elements"].length; i1++) {

                    drive_time = drive_time + Number(response["rows"][0]["elements"][i1]["duration"]["value"])
                    drive_distance = drive_distance + Number(response["rows"][0]["elements"][i1]["distance"]["value"])

                  }

                }


                this.calcDrivingTime =  this.$moment().startOf("day").seconds(drive_time).format("HH:mm:ss")
                this.calcDrivingDistance = Number(drive_distance) / 1000

                this.calcDrivingDistance = this.calcDrivingDistance.toFixed(2)

                console.log("Fahrtzeit: " + drive_time + " in Sekunden")
                console.log("Fahrtzeit: " + this.calcDrivingTime + " ")
                console.log("Wegstrecke: " + drive_distance + " in Meter")
        */

      });





    });



    /*
          const service = new gmapApi.google.maps.DistanceMatrixService();

          // const origin1 = { lat: 55.93, lng: -3.118 };
          const origin1 = "Eningen";
          const destinationA = "Reutlingen";
          const destinationB = "Betzingen";
          const request = {
            origins: [origin1],
            destinations: [destinationA, destinationB],
            travelMode: gmapApi.google.maps.TravelMode.DRIVING,
            unitSystem: gmapApi.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          };

          service.getDistanceMatrix(request).then((response) => {
            // put response

            console.log("response")
            console.log(response)

          });

    */


  },


    checkRefs(){

        if(this.$refs.myMapRef != undefined){
          clearInterval(this.intervalCheckRefs)
          this.initMap()
        }

      },


    initMap(){

        this.$nextTick(() => {
          console.log(this.$refs.myMapRef)

          if(this.$refs.myMapRef != undefined){

          this.$refs.myMapRef.$mapPromise.then((map) => {
            // Create the DIV to hold the control and call the CenterControl()
            // constructor passing in this DIV.
  /*
            const centerControlDiv = document.createElement('div');
            this.addCenterControl(centerControlDiv, map);
            map.controls[this.google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  */


            // map.setOptions({draggable: false, zoomControl: false, disableDoubleClickZoom: true})


            this.google.maps.event.addListener(
                map,
                'bounds_changed',
                onBoundsChanged,
            );

            function onBoundsChanged() {
              if (
                  map.getDiv().firstChild.clientHeight === window.innerHeight &&
                  map.getDiv().firstChild.clientWidth === window.innerWidth
              ) {
                console.log('FULL SCREEN');


              if(localStorage.map_customcontrols == "false"){
                const centerControlDiv = document.createElement('div');
                addCenterControl(centerControlDiv, map);
                map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);

                localStorage.map_customcontrols = "true"
              }



              } else {
               console.log('NOT FULL SCREEN');
               map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].clear();

                localStorage.map_customcontrols = "false"
              }
            }

            function addCenterControl(controlDiv, map) {


             const controlButton = document.createElement('button');
             // const controlButton = document.createElement('button');

              controlButton.style.backgroundColor = '#fff';
              controlButton.style.border = '2px solid #fff';
              controlButton.style.borderRadius = '3px';
              controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
              controlButton.style.color = 'rgb(25,25,25)';
              controlButton.style.cursor = 'pointer';
              controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
              controlButton.style.fontSize = '16px';
              controlButton.style.lineHeight = '38px';
              controlButton.style.margin = '8px 0 22px';
              controlButton.style.padding = '0 5px';
              controlButton.style.textAlign = 'center';

              controlButton.textContent = 'Center Map';
              controlButton.title = 'Click to recenter the map';
              controlButton.type = 'button';

              const controlUI = document.createElement('div');
              //this.controlUIGlobal = document.createElement('div');

              //this.controlUIGlobal.innerHTML = `
              controlUI.innerHTML = ``

              /*
              controlUI.innerHTML = `
                 <div
          style="
          width: 350px;
          height: 600px;
          color: black;
          background: white;

          padding: 1rem;">
           <div
           style="
           position: absolute;
           top: 20px;
           left: 50px;
           "
           ><h2>WEGSTRECKENOPTIMIERUNG</h2></div>



          </div>

              `;
  */
              controlDiv.appendChild(controlUI);
             // controlDiv.appendChild(controlButton);

  /*
              var node = controlDiv.lastElementChild
              node.innerHTML = "<div style='background-color: white; height: 400px; width: 400px;'>" + "</div>"
  */

              // controlUI.addEventListener()



              controlUI.addEventListener('click', () => {
                // Do what ever you want to happen on click event


               console.log(map.key)

                controlUI.innerHTML = ``


          /*
                map.setCenter({
                  lat: 53.57532,
                  lng: 10.01534,
                });
           */

              });
            }




  // https://developers.google.com/maps/documentation/javascript/examples/control-custom



            this.options = {

              streetViewControl: false,
              mapTypeControl: false,


              zoomControlOptions: {
                position: this.google.maps.ControlPosition.LEFT_BOTTOM
              },

              fullscreenControlOptions:{
                position: this.google.maps.ControlPosition.LEFT_TOP
              },


              /*
              mapTypeControlOptions: {
                position: this.google.maps.ControlPosition.TOP_RIGHT
              },
              streetViewControlOptions: {
                position: this.google.maps.ControlPosition.RIGHT_TOP
              }
              */


            }


          });




          }


        })




      },


  async setMarkerPoints() {

    // Holt die aktuelle GPS Position
/*
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
    });

 */

    // Liest alle offenen Aufträge aus

    // Einstellungen
    var param = {
      table: "task",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "false", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "WHERE (tour_id='' OR tour_id IS NULL) OR (temp_tour_id = '" + this.temp_tour_id + "') AND (pickup_date IS NULL OR pickup_date='')",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    // console.log(result)

    // Marker Array zurücksetzen
    this.markers = []
    this.waypoints = []

    var selected = false
    var color = "blue"
    var shape = ""
    var sprinter = ""
    var label = ""
    var label_debug = ""


    for (var i1 = 0; i1 < result.length; i1++) {

      if (result[i1]["lat"] != "" && result[i1]["lat"] != undefined){


        if(result[i1]["prio"] == "1"){
          color = "green"
        }
        if(result[i1]["prio"] == "2"){
          color = "orange"
        }
        if(result[i1]["prio"] == "3"){
          color = "red"
        }


        if(result[i1]["worker_add"] == "AUTO"){
          shape = "triangle"
        }else{
          shape = "circle"
        }


        console.log(result[i1]["onlysprinter"])
        if(result[i1]["onlysprinter"] == "true"){
          sprinter = "_sprinter"
        }else{
          sprinter = ""
        }


        // Prüfen ob Abholpunkt bereits ausgewählt ist
        if(result[i1]["temp_tour_id"] == this.temp_tour_id){

          selected = true
          color = "pink"
          label = result[i1]["tour_position"]
          label_debug = result[i1]["zipcode"]

          sprinter = ""

          // Wegpunkte werden gesetzt
          console.log("Wegpunkte werden gesetzt")
          this.waypoints.push({id: label, lat: result[i1]["lat"], lng: result[i1]["lng"], address: result[i1]["street"] + ", " + result[i1]["zipcode"] + ", " + result[i1]["city"]})

        }else{
          selected = false
          label = result[i1]["order_qty"]
          label_debug = result[i1]["zipcode"]
        }


        this.markers.push({
          lat: result[i1]["lat"],
          lng: result[i1]["lng"],
          prio: result[i1]["prio"],
          label: label,
          label_debug: label_debug,
          color: color,
          shape: shape,
          sprinter: sprinter,
          selected: selected,
          id: result[i1]["id"]
        })
      }

    }

    console.log("waypoints fertig")
    console.log(this.waypoints)



    /* SAMMELSTELLEN */

    // Einstellungen
    var param2 = {
      table: "parking",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "false", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data2 = {
    }

    var result2 = await this.$func.queryAPI(param2, data2)



    for (var i3 = 0; i3 < result2.length; i3++) {

      if (result2[i3]["lat"] != "" && result2[i3]["lat"] != undefined){

        if(result2[i3]["id"] == this.parking_id){


          if(this.waypoints.length == 0){
            alert("Bitte erst Abholpunkte auswählen. Oder Start Punkt Sammelstelle über Dropdown auswählen!")

            color = "place"
            shape = "collection"
            selected = false

            this.parking_id = ""
            this.parking_address = ""
            this.parking_lat = ""
            this.parking_lng = ""


            this.markers.push({
              lat: result2[i3]["lat"],
              lng: result2[i3]["lng"],
              prio: "",
              label: "",
              label_debug: "",
              color: color,
              sprinter: "",
              shape: shape,
              selected: selected,
              id: result2[i3]["id"]
            })

            return
          }


          this.end_point = this.parking_address


          color = "place_parked"
          shape = "collection"
          selected = true





        }else{
          color = "place"
          shape = "collection"
          selected = false
        }

        this.markers.push({
          lat: result2[i3]["lat"],
          lng: result2[i3]["lng"],
          prio: "",
          label: "",
          label_debug: "",
          color: color,
          sprinter: "",
          shape: shape,
          selected: selected,
          id: result2[i3]["id"]
        })
      }

    }



      if(this.show_planed_tasks == false){
        return
      }



    /* Optionale Anzeige für bereits geplante Aufträge/Touren */


    // Einstellungen
    var param1 = {
      table: "task",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "false", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "WHERE (tour_id <> '' AND tour_id IS NOT NULL) AND (temp_tour_id <> '" + this.temp_tour_id + "') AND (pickup_date IS NULL)",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data1 = {
    }

    var result1 = await this.$func.queryAPI(param1, data1)


    console.log("result1")
    console.log(result1)


    selected = false
    color = "blue"
    shape = ""
    sprinter = ""
    label = ""
    label_debug = ""


    for (var i2 = 0; i2 < result1.length; i2++) {

      if (result1[i2]["lat"] != "" && result1[i2]["lat"] != undefined){

        color = "grey"
        shape = "rectangle"



        console.log(result1[i2]["tour_id"])

          selected = false
          label = result1[i2]["order_qty"]
          label_debug = result1[i2]["tour_id"]


        this.markers.push({
          lat: result1[i2]["lat"],
          lng: result1[i2]["lng"],
          prio: result1[i2]["prio"],
          label: label,
          label_debug: label_debug,
          color: color,
          sprinter: "",
          shape: shape,
          selected: selected,
          id: result1[i2]["id"]
        })
      }

    }


    console.log("MyMarkers")
    console.log(this.markers)




    /*
        this.markers = [
          {
            lat: 21.1594627,
            lng: 72.6822083,
            label: 'Surat'
          },
          {
            lat: 23.0204978,
            lng: 72.4396548,
            label: 'Ahmedabad'
          },
          {
            lat: 22.2736308,
            lng: 70.7512555,
            label: 'Rajkot'
          }
        ];
    */
  },

  btnCancel(){
    this.$router.push({ name: this.mainview })
  },

  async saveData(){
    console.log("saveData")

    if(this.driver_id == ""){
      this.snackbar_title = "Bitte Fahrer auswählen!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    if(this.car == ";"){
      this.snackbar_title = "Bitte Fahrzeug auswählen!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    if(this.date == ""){
      this.snackbar_title = "Geplantes Tour Datum eingeben!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    if(this.qty == "0"){
      this.snackbar_title = "Bitte Aufträge auswählen!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    this.car_capacity = await this.checkTourCapacity(this.car_id)


    var statement

    if(this.id == ""){
      statement = "insert"
    }else{
      statement = "update"
    }

    // Parameter
    var param = {
      table: this.table,
      statement: statement,
      type: "",
      where: "true",
      selector: "id",
      value: this.id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    console.log("this.calc_driving_time")
    console.log(this.calc_driving_time)


    // Data
    var data = {
      planed_date: this.date,
      planed_date_timestamp: this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD"),
      date: this.$moment(Date.now()).format("DD.MM.YYYY"),

      driver_id: this.driver_id,
      driver_name: this.driver_name,
      temp_tour_id: this.temp_tour_id,
      car_id: this.car_id,
      car_description: this.car_description,
      car_capacity: this.car_capacity,
      qty: this.qty,
      article: this.article,
      note: this.note,
      task_qty: this.num_of_pos,
      start_point: this.start_point,
      end_point: this.end_point,

      parking_id: this.parking_id,
      parking_address: this.parking_address,
      parking_lat: this.parking_lat,
      parking_lng: this.parking_lng,

      calc_driving_time: this.calc_driving_time,
      calc_driving_distance: this.calc_driving_distance,
      worker: localStorage.username,
    }


    console.log(data)

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    if(statement == "insert"){
      this.id = result["value"]
    }

    await this.setTourId(this.temp_tour_id, this.id)

    this.snackbar_title = "Tour gespeichert!"
    this.snackbar_color = "green"
    this.snackbar = true


  },

  async selectFolgeauftraege(){
    console.log("selectFolgeauftraege")

    this.loading_dialog = true

    // Einstellungen
    var param = {
      table: "task",
      statement: "custom",
      type: "",
      where: "false",
      selector: "",
      value: "",
      short: "false", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "SELECT id FROM task WHERE pickup_date IS NULL AND (tour_id = '' OR TOUR_id IS NULL) AND worker_add='AUTO'",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)


    for (var i1 = 0; i1 < result.length; i1++) {
        await this.add2Tour(result[i1]["id"])
    }

    this.loading_dialog = false

  },

  async selectByZipcode(){

    console.log("selectByZipcode")

    if(this.group_tour_id == ""){
      this.snackbar_title = "Bitte PLZ Bereich auswählen!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    this.loading_dialog = true


    // Einstellungen
    var param = {
      table: "group_tour",
      statement: "select",
      type: "detail",
      where: "true",
      selector: "id",
      value: this.group_tour_id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    var selected_zipcodes
    var id2add

    selected_zipcodes = result[0]["zipcode"].toString().split(",")

    for (var i1 = 0; i1 < selected_zipcodes.length; i1++) {

      id2add = await this.getTaskByZipcode(selected_zipcodes[i1])

      for (var i2 = 0; i2 < id2add.length; i2++) {
        await this.add2Tour(id2add[i2]["id"])
      }

      i2 = 0

    }


    this.loading_dialog = false

    // console.log("selected_zipcodes: " + selected_zipcodes)
    // console.log("group_tour_zipcode: " + this.group_tour_zipcode)


  },

  async getTaskByZipcode(zipcode){
     //  console.log("getTaskByZipcode")

    // Einstellungen
    var param = {
      table: "task",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "WHERE zipcode LIKE '" + zipcode + "%' AND (tour_id  IS NULL OR tour_id='') AND (temp_tour_id <> '" + this.temp_tour_id + "' OR temp_tour_id IS NULL)",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    // console.log("extension: " + param.extension)

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    return result

  },

  async setTourId(temp_tour_id, id){

    // Parameter
    var param = {
      table: "task",
      statement: "update",
      type: "",
      where: "true",
      selector: "temp_tour_id",
      value: temp_tour_id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    console.log("KEINE ID ????: " + id)

    // Data
    var data = {
      tour_id: id,
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

  },

  async clickMarker(id, selected, shape){
    console.log("clickMarker: " + id)
    console.log("shape: " + shape)


    if(shape == "rectangle"){
      return
    }


    if(shape == "collection"){
      console.log("collection")

      if(id == this.parking_id){
        this.parking_id = ""
        this.parking_address = ""
        this.parking_lat = ""
        this.parking_lng = ""

        this.setMarkerPoints()

        this.calcRouteDirectionsService()

        return
      }

      // Einstellungen
      var param = {
        table: "parking",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.parking_id = id
      this.parking_address = result[0]["street"] + ", " + result[0]["zipcode"] + " " + result[0]["city"]
      this.parking_lat = result[0]["lat"]
      this.parking_lng = result[0]["lng"]

      this.setMarkerPoints()

      this.calcRouteDirectionsService()

      return
    }




    if(selected == true){
      await this.delFromTour(id)
      await this.setMarkerPoints()
    }else{
      await this.add2Tour(id)
      await this.setMarkerPoints()
    }


    // ToDo: Wegstrecke neu berechnen

    this.calcRouteDirectionsService()



  },

  async add2Tour(id){
    // temp_tour_id

    // Parameter
    var param = {
      table: 'task',
      statement: 'update',
      type: "",
      where: "true",
      selector: "id",
      value: id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }


    // Data
    var data = {
      temp_tour_id: this.temp_tour_id,
      tour_position: Number(await this.checkNumofPosition()) + 1

    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    this.reload_table_unassigned()
    this.reload_table_selected()


  },

  async delFromTour(id){
    // temp_tour_id

    // Parameter
    var param = {
      table: 'task',
      statement: 'update',
      type: "",
      where: "true",
      selector: "id",
      value: id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }


    // Data
    var data = {
      temp_tour_id: "",
      tour_id: "",
      tour_position: ""
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    await this.resortPosition()

    this.reload_table_unassigned()
    this.reload_table_selected()

  },

  async resortPosition(){

    // Parameter
    var param = {
      table: 'task',
      statement: 'select',
      type: "list",
      where: "true",
      selector: "temp_tour_id",
      value: this.temp_tour_id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "ORDER BY `tour_position` ASC",
    }

    // Data
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)

    if(result.length == 0){
      return
    }

    for (var i1 = 0; i1 < result.length; i1++) {
      await this.changePositionByID(result[i1]["id"], i1 + 1)
    }


    console.log(result)

  },

  async changePositionByID(id, pos_new){

    // Parameter
    var param = {
      table: 'task',
      statement: 'update',
      type: "",
      where: "true",
      selector: "id",
      value: id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Data
    var data = {
      tour_position: pos_new,
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

  },

  // Liefert Anzahl der Positionen
  async checkNumofPosition(){

    // Parameter
    var param = {
      table: 'task',
      statement: 'select',
      type: "detail",
      where: "true",
      selector: "temp_tour_id",
      value: this.temp_tour_id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Data
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    console.log(result)

    return result.length ?? 0

  },

  async loadData(){
    console.log("loadData")

    // Einstellungen
    var param = {
      table: "tour",
      statement: "select",
      type: "detail",
      where: "true",
      selector: "id",
      value: this.id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)

    console.log(result)

    this.driver_id = result[0]["driver_id"]
    this.driver_name = result[0]["driver_name"]

    this.car_id = result[0]["car_id"]
    this.car_description = result[0]["car_description"]
    this.car_capacity = result[0]["car_capacity"]

    this.date = result[0]["planed_date"]

    this.temp_tour_id = result[0]["temp_tour_id"]
    this.tour_id = result[0]["id"]

    this.start_point = result[0]["start_point"]
    this.end_point = result[0]["end_point"]
    this.parking_address = result[0]["parking_address"]
    this.calc_driving_time = result[0]["calc_driving_time"]
    this.calc_driving_distance = result[0]["calc_driving_distance"]

    console.log("temp_tour_id")
    console.log("this.temp_tour_id" + this.temp_tour_id)

    this.qty = result[0]["qty"]
    this.article = result[0]["article"]
    this.note = result[0]["note"]

    this.reload_table_selected()


    this.reRenderer = false


    this.$nextTick(() => {
      this.reRenderer = true
    })


  },

  reload_table_selected(){
    this.key_table_selected++
    // console.log("this.key_table_selected: " + this.key_table_selected)

    this.checkQty()

    this.setMarkerPoints()
  },

  reload_table_unassigned(){
    this.key_table_unassigned++
    // console.log("this.key_table_selected: " + this.key_table_unassigned)

    this.checkQty()

    this.setMarkerPoints()
  },


  async checkTourCapacity(id){

    // Einstellungen
    var param = {
      table: "cars",
      statement: "select",
      type: "detail",
      where: "true",
      selector: "id",
      value: id,
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)
    // console.log(result)

    return result[0]["capacity"]

  },



  async checkQty(){
    console.log("checkQty")

    // Einstellungen
    var param = {
      table: "task",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "false", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "WHERE (temp_tour_id = '" + this.temp_tour_id + "' OR tour_id = '" + this.tour_id + "') AND (pickup_date IS NULL)",
    }

    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {
    }

    var result = await this.$func.queryAPI(param, data)

  //  console.log("checkQty ----!")
  //  console.log(result)

    this.qty = 0
    this.article = ""
    this.num_of_pos = result.length

   // console.log("result.length" + result.length)

    for (var i1 = 0; i1 < result.length; i1++) {
   //   console.log("qty: " + result[i1]["order_qty"])
      this.qty =  Number(this.qty) + Number(result[i1]["order_qty"])
      this.article = this.article +  result[i1]["article_name"] + ","
    }



    var article_array = this.article.split(",")

    let uniqueChars = [...new Set(article_array)];

   // console.log(uniqueChars.join(','))
    this.article = uniqueChars.join(',')

  }


},

  watch:{


    driver: function () {

      // console.log(this.driver)

      if(this.driver.trim() != ";" && this.driver.includes(";") == true){
        this.driver_id = this.driver.split(";")[1]
        this.driver_name = this.driver.split(";")[0]
      }

    },

    car: function () {

      // console.log(this.car)

      if(this.car.trim() != ";" && this.car.includes(";") == true){
        this.car_id = this.car.split(";")[1]
        this.car_description = this.car.split(";")[0]
      }


    },

    show_planed_tasks: function(){

      this.setMarkerPoints()

    },

    group_tour: function () {

      // console.log(this.group_tour)

      if(this.group_tour.trim() == ";"){
        this.group_tour_id = ""
        this.group_tour_zipcode = ""
      }

      if(this.group_tour.trim() != ";" && this.group_tour.includes(";") == true){
        this.group_tour_id = this.group_tour.split(";")[1]
        this.group_tour_zipcode = this.group_tour.split(";")[0]
      }

    },

    tab: function(){
      // console.log(this.tab)

      if(this.tab == 1){
        this.initMap()
      }

    },


    start_point: function (){
      this.calcRoute()
    }

  },


  computed: {

    google: gmapApi,



    headers_add () {
      return [
        { text: 'Prio', value: 'prio' },
        { text: 'Ausführungsfrist', value: 'execution_date_timestamp' },
        { text: 'Auftragsdatum', value: 'date_timestamp' },
        { text: 'Reklamationsdatum', value: 'complaint_date' },
        { text: 'Auftragsnummer', value: 'document_no' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Anzahl', value: 'order_qty' },
        { text: 'Bemerkung', value: 'note' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Ort', value: 'city' },
        { text: 'Strasse', value: 'street' },
        { text: '', value: 'onlysprinter' },
        { text: '', value: 'select_add' },
      ]
    },
    headers_selected () {
      return [
        { text: 'Prio', value: 'prio' },
        { text: 'Pos', value: 'tour_position' },
        { text: 'Ausführungsfrist', value: 'execution_date_timestamp' },
        { text: 'Auftragsdatum', value: 'date_timestamp' },
        { text: 'Auftragsnummer', value: 'document_no' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', value: 'company' },
        { text: 'Anzahl', value: 'order_qty' },
        { text: 'Artikel', value: 'article_name' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Ort', value: 'city' },
        { text: 'Strasse', value: 'street' },
        { text: 'Bemerkung', value: 'note' },
        { text: '', value: 'onlysprinter' },
        { text: '', value: 'select_delete' },
      ]
    },


  },




}
</script>

<style scoped>

</style>
