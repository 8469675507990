<template>
<div
style="
width: 100%;
height: 1900px;

"
>

  <div
      style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
  >

    <DialogDate
        v-bind:label="'Von'"
        v-bind:initial_date="date_from"
        v-bind:clearable="false"
        v-model="date_from"
    ></DialogDate>

    <DialogDate
        v-bind:label="'Bis'"
        v-bind:initial_date="date_to"
        v-bind:clearable="false"
        v-model="date_to"
    ></DialogDate>

  </div>


  <v-overlay
      :value="overlay"
  >


    <v-progress-circular
        indeterminate
        color="black"
        style="margin: 20px;"
    ></v-progress-circular>


    Daten werden geladen...


  </v-overlay>






  <div
  style="
  position: absolute;
  top: 0px;

  margin: 20px;"
  >

    <div>
      <cLine

          style="
           width: 700px;
           height: 300px;
          "

          v-if="renderComponentLineChart"
          :chartData="chartData"
      >
      </cLine>
    </div>

  </div>


  <div
      style="
  position: absolute;
  top: 100px;
  left: 800px;
  margin: 20px;"
  ><h1>ca. {{rating_in_percent}} %</h1>
  <p>⌀ Reifen Qualität (Gutreifenanteil)</p>
  </div>


<div
style="
  position: absolute;
  top: 450px;
  height: 1100px;

"
>
  <h2>Schlechteste Reifen Qualität nach Kunden</h2> (Festgelegter Zeitraum)

  <div
  style="margin: 15px;"
  ></div>

  <v-data-table

      style="
      width: 1250px;
      height: 700px;
      "



      v-if="renderComponent"

      :height="height"
      :headers="headers"
      :items="data"
      :key="key"
      item-key="id"
      :items-per-page="100"
      class="elevation-7"
      :search="search"
      :custom-filter="filterOnlyCapsText"

      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-options': [50, 100, 200, -1],
           'items-per-page-text':'pro Seite'
      }"


  >

    <template v-slot:item.view="{item}">

      <v-row>
        <div
            style="
              display: inline;
              white-space: nowrap;"
        >
          <v-icon style="padding: 3px;" @click="openCustomer(item.customer_id)">mdi-eye</v-icon>

        </div>

      </v-row>
      <!--
      <v-hover v-slot:default="{ hover }">
        <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
        </v-badge>
      </v-hover>
      -->
    </template>


    <template v-slot:item.rating="{item}">

      <v-row>
        <div
            style="
              display: inline;
              white-space: nowrap;"
        >
          <v-icon v-if="item.rating == 1" color="red" style="padding: 3px;">mdi-star</v-icon>
          <v-icon v-else-if="item.rating == 2" color="orange" style="padding: 3px;">mdi-star</v-icon>
          <v-icon v-else-if="item.rating == 3" color="yellow" style="padding: 3px;">mdi-star</v-icon>
          <v-icon v-else-if="item.rating == 4" color="lime" style="padding: 3px;">mdi-star</v-icon>
          <v-icon v-else-if="item.rating == 5" color="green" style="padding: 3px;">mdi-star</v-icon>
          <div v-else>{{item.rating}}</div>

          {{item.rating}}

        </div>

      </v-row>
      <!--
      <v-hover v-slot:default="{ hover }">
        <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
        </v-badge>
      </v-hover>
      -->
    </template>


  </v-data-table>

</div>

</div>

</template>

<script>


import DialogDate from "../components/DialogDate"
import cLine from "../components/cLine.vue"

export default {
  name: "AnalyticsRating",

  components:{
    DialogDate,
    cLine,
  },


  data() {
    return {

      title: "Verkäufe",
      title2: "",

      chartData: Object,

      key: 0,

      height: 600,

      data: [],
      data_chart: [],

      dialog: false,

      search: '',
      sort: '',
      limit: '',

      sync: true,
      snackbar: false,

      disposer: [],
      disposal: [],
      purchaser:[],
      purchaser_card:[],

      number_of_purchaser: 0,

      select: '',

      renderComponent: false,
      renderComponentLineChart: false,
      overlay: false,

      default_range_in_days: 90,

      panel: [0],

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */


      rating_last2month: 0,
      rating_in_percent: 0,


      Chart: false,

      chartDataPie1: '',
      chartDataPie2: '',


      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },

  async created() {

    this.sync = true


    this.overlay = true

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")



    await this.loadData()

    await this.calcRating()

    await this.calcAverageRating()


    this.overlay = false

    this.sync = false

  },


  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.loadData()
    },


  },


  methods:{


    async calcAverageRating(){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")



      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(rating) FROM task_items WHERE (wasteno='160103' AND pickup_qty IS NOT NULL) AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)


      console.log(result1[0]["AVG(rating)"])
      console.log(param1.extension)

      this.rating_last2month = Number(result1[0]["AVG(rating)"]).toFixed(2)

      // Einstellungen
      var param2 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

      // console.log("result2")
      // console.log(result2)


      switch (Math.round(this.rating_last2month)) {
        case 1:
          this.rating_in_percent = result2[0]["wert"]
          break;
        case 2:
          this.rating_in_percent = result2[1]["wert"]
          break;
        case 3:
          this.rating_in_percent = result2[2]["wert"]
          break;
        case 4:
          this.rating_in_percent = result2[3]["wert"]
          break;
        case 5:
          this.rating_in_percent = result2[4]["wert"]
          break;
        default:
          this.rating_in_percent = "XX"
      }







    },


    async calcRatingByKW(date_from, date_to, kw){

      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(rating) FROM task_items WHERE (wasteno='160103' AND pickup_qty IS NOT NULL) AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)


      this.data_chart.push({avg: Number(result1[0]["AVG(rating)"]).toFixed(2), kw: kw})

    },


    async getRange(startDate, endDate, type) {

          let fromDate = this.$moment(startDate)
          let toDate = this.$moment(endDate)
          let diff = toDate.diff(fromDate, type)

          let range = []
          for (let i = 0; i < diff; i++) {
            range.push(this.$moment(startDate).add(i, type).format("ww YYYY"))
          }

          return range

    },


    async getKW(){


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")


      let range = await this.getRange(date_from, date_to, 'week')


      console.log(range)

//      var current_week = this.$moment().week()

      var from, to

      this.data_chart = []


      for (var i99 = 0; i99 <= range.length; i99++) {

        if(range[i99] == undefined){
          break
        }

        console.log(this.$moment(range[i99], "ww YYYY").day("Monday").format("YYYY-MM-DD"))
        console.log(this.$moment(range[i99], "ww YYYY").day("Friday").format("YYYY-MM-DD"))

        from = this.$moment(range[i99], "ww YYYY").day("Monday").format("YYYY-MM-DD")
        to = this.$moment(range[i99], "ww YYYY").day("Friday").format("YYYY-MM-DD")


          await this.calcRatingByKW(from, to, range[i99])


      }


      this.chartData = {
        labels: [

          /*
          this.data[6]["kw"],
          this.data[5]["kw"],
          this.data[4]["kw"],
          this.data[3]["kw"],
          this.data[2]["kw"],
          this.data[1]["kw"],
          this.data[0]["kw"],
          */

        ],
        datasets: [
          {
            label: 'Reifen Qualität Verlauf',
            backgroundColor: '#f87979',
            data: [

                /*
                this.data[6]["avg"],
                this.data[5]["avg"],
                this.data[4]["avg"],
                this.data[3]["avg"],
                this.data[2]["avg"],
                this.data[1]["avg"],
                this.data[0]["avg"],
                */

            ]

          }
        ]
      }



      for (var i98 = 0; i98 <= range.length; i98++) {

        if(this.data_chart[i98] == undefined){
          break
        }

        this.chartData["labels"].push(this.data_chart[i98]["kw"])
        this.chartData["datasets"][0]["data"].push(this.data_chart[i98]["avg"])

      }


      // Remove MyComponent from the DOM
      this.renderComponentLineChart = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponentLineChart = true;



    },


    openCustomer(id){

      // this.$router.push({ path: "ListCheck" + "/" + id, params:{id: id} })

      let routeData = this.$router.resolve({ name: "ListCheck", params:{id: id} })
      window.open(routeData.href, '_blank');

    },

    async calcRating(){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(rating) FROM task_items WHERE (wasteno='160103' AND pickup_qty IS NOT NULL) AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

    },








    async loadData(){

      this.overlay = true


      this.data = []

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT customer_id, rating, count(*), sum(pickup_qty)  FROM task_items WHERE (wasteno='160103' AND pickup_qty IS NOT NULL)  AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' GROUP BY rating, customer_id ORDER BY rating ASC, sum(pickup_qty) DESC, count(*) DESC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log(result1)

      var company, group_type_text

      const go = async () => {

        for (var i99 = 0; i99 < result1.length; i99++) {

          company = await this.getCustomerName(result1[i99]["customer_id"])
          group_type_text = await this.getCustomerGroupTypeText(result1[i99]["customer_id"])

          if(company != undefined || group_type_text != undefined){

          this.data.push({
            customer_id: result1[i99]["customer_id"],
            company: company,
            group_type_text: group_type_text,
            rating: result1[i99]["rating"],
            qty: result1[i99]["count(*)"],
            pickup_qty: result1[i99]["sum(pickup_qty)"],
          })

          }

        }
      }

      await go()

      await this.calcAverageRating()

      await this.getKW()

      await this.calcRatingByKW()

      // Remove MyComponent from the DOM
      this.renderComponent = false;



      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      this.key = this.key + 1

      // Add the component back in
      this.renderComponent = true;



      this.overlay = false


    },




    async getCustomerName(id){

      // Einstellungen
      var param1 = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)


      if(result1.length == 0){
        return
      }

      return result1[0]["company"]

    },

    async getCustomerGroupTypeText(id){

      // Einstellungen
      var param1 = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      if(result1.length == 0){
        return
      }


      return result1[0]["group_type_text"]

    },


    filterOnlyCapsText (value, search) {

      console.log("filter")

      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
    },


  },


  computed: {
    headers () {
      return [
        { text: 'Kunde', align: 'start', sortable: false, value: 'company' },
        { text: 'Untergruppe', value: 'group_type_text', sortable: false, },
        { text: 'Anzahl der Abholungen', value: 'qty', sortable: false, },
        { text: 'Anzahl der Reifen', value: 'pickup_qty', sortable: false, },
        { text: 'Bewertung', value: 'rating', sortable: false, },
        { text: '', value: 'view', sortable: false, },
      ]
    },
  },


}
</script>

<style scoped>

</style>
