var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"1900px"}},[_c('div',{staticStyle:{"position":"absolute","right":"20px","top":"0px"}},[_c('DialogDate',{attrs:{"label":'Von',"initial_date":_vm.date_from,"clearable":false},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}}),_c('DialogDate',{attrs:{"label":'Bis',"initial_date":_vm.date_to,"clearable":false},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{staticStyle:{"margin":"20px"},attrs:{"indeterminate":"","color":"black"}}),_vm._v(" Daten werden geladen... ")],1),_c('div',{staticStyle:{"position":"absolute","top":"0px","margin":"20px"}},[_c('div',[(_vm.renderComponentLineChart)?_c('cLine',{staticStyle:{"width":"700px","height":"300px"},attrs:{"chartData":_vm.chartData}}):_vm._e()],1)]),_c('div',{staticStyle:{"position":"absolute","top":"100px","left":"800px","margin":"20px"}},[_c('h1',[_vm._v("ca. "+_vm._s(_vm.rating_in_percent)+" %")]),_c('p',[_vm._v("⌀ Reifen Qualität (Gutreifenanteil)")])]),_c('div',{staticStyle:{"position":"absolute","top":"450px","height":"1100px"}},[_c('h2',[_vm._v("Schlechteste Reifen Qualität nach Kunden")]),_vm._v(" (Festgelegter Zeitraum) "),_c('div',{staticStyle:{"margin":"15px"}}),(_vm.renderComponent)?_c('v-data-table',{key:_vm.key,staticClass:"elevation-7",staticStyle:{"width":"1250px","height":"700px"},attrs:{"height":_vm.height,"headers":_vm.headers,"items":_vm.data,"item-key":"id","items-per-page":100,"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-options': [50, 100, 200, -1],
           'items-per-page-text':'pro Seite'
      }},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},on:{"click":function($event){return _vm.openCustomer(item.customer_id)}}},[_vm._v("mdi-eye")])],1)])]}},{key:"item.rating",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[(item.rating == 1)?_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"red"}},[_vm._v("mdi-star")]):(item.rating == 2)?_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"orange"}},[_vm._v("mdi-star")]):(item.rating == 3)?_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"yellow"}},[_vm._v("mdi-star")]):(item.rating == 4)?_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"lime"}},[_vm._v("mdi-star")]):(item.rating == 5)?_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"green"}},[_vm._v("mdi-star")]):_c('div',[_vm._v(_vm._s(item.rating))]),_vm._v(" "+_vm._s(item.rating)+" ")],1)])]}}],null,false,2744424895)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }