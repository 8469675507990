<template>
<div
style="
width: 100%;
height: 4000px"
>

  <div><h2>{{title}}</h2></div>
  <div>{{title2}}</div>


  <div
      style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
  >

    <DialogDate
        v-bind:label="'Von'"
        v-bind:initial_date="date_from"
        v-bind:clearable="false"
        v-model="date_from"
    ></DialogDate>

    <DialogDate
        v-bind:label="'Bis'"
        v-bind:initial_date="date_to"
        v-bind:clearable="false"
        v-model="date_to"
    ></DialogDate>





    <!--
    <div

    >
      <v-card
          class="mx-auto"
          width="300"
          height="500"
          style="margin: 15px;"

      >
        <v-card-title>ZUSAMMENFASSUNG</v-card-title>
        <div
            style="margin: 20px;"
        >



        </div>


      </v-card>


    </div>
    -->


  </div>


<div
    style="
        position: absolute;
        left: 80px;
        top: 80px;
        "
>
  <b>Verkäufe nach Länder (Abnahme in Stück)</b>
<!--
  <cPie
      v-if="Chart"

      :height="250"
      :chartData="chartDataPie1"
  >

  </cPie>
-->


    <Bar
            v-if="Chart"

            :chart-options="chartOptions"
            :chart-data="chartDataPie1"
            :chart-id="'2'"
            :dataset-id-key="'2'"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="500"
            :height="300"
    />



</div>

  <div
      style="
        position: absolute;
        left: 80px;
        top: 450px;
        "
  >
    <b>Top 5 Käufer (Abnahme in Stück)</b>


    <Bar
        v-if="Chart"

        :chart-options="chartOptions"
        :chart-data="chartDataPie2"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="500"
        :height="300"
    />






  </div>



  <div
  style="
  position: absolute;
  right: 40px;
  top: 750px;
  "
  >

    <v-row>

      <div
      style="
      margin: 10px;"
      >Käufer Anzahl (Zeitraum): {{number_of_purchaser}}</div>


    <v-combobox
        v-model="select"
        :items="purchaser"
        item-text="name"
        item-value="name"
        label="Käufer"
        @change="onChange"
        outlined
        dense
    ></v-combobox>

    <v-btn

        height="30"
        width="30"

        style="
        width: 40px;
        height: 40px;
        margin-left: 10px;"
        @click="add_purchaser(select)"
    ><v-icon v-if="purchaser_card.length > 0">mdi-delete</v-icon><v-icon v-else>mdi-chart-box-plus-outline</v-icon></v-btn>
    </v-row>


  </div>



  <div
  style="
  position: absolute;

  right: 20px;
  top: 150px;
  "
  v-if="Chart"
  >

    <CardAnalyticsPurchaser
        v-for="item in purchaser_card"
        :key="item.id"
        v-bind:purchaser_name="item.name"
        v-bind:date_from="date_from"
        v-bind:date_to="date_to"
    ></CardAnalyticsPurchaser>

  </div>


  <div
  style="
  position: absolute;
  left: 60px;
  top: 800px;
  "
  >

    <div
    style="margin: 20px;"
    >
    <p><b>Zusammenfassung</b></p>
    </div>
  <v-table
  style="width: 800px"
  >
    <thead>
    <tr>
      <th class="text-center"
      style="width: 200px;"
      >
        Name
      </th>
      <th class="text-center"
          style="width: 200px;"
      >
        Abnahme in Tonnen
      </th>
      <th class="text-center"
          style="width: 200px;"
      >
        Abnahme in Stück
      </th>
      <th class="text-center"
          style="width: 200px;"
      >
        Anzahl der Verkäufe
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="item in purchaser"
        :key="item.name"
    >
      <td
          class="text-left"
      >{{ item.name }}</td>
      <td
          class="text-center"
      >{{ item.weight }}</td>
      <td
          class="text-center"
      >{{ item.qty }}</td>
      <td
          class="text-center"
      >{{ item.num }}</td>
    </tr>





    <tr
    >
      <td
          class="text-left"
      >&nbsp;</td>
      <td
          class="text-center"
      >&nbsp;</td>
      <td
          class="text-center"
      >&nbsp;</td>
      <td
          class="text-center"
      >&nbsp;</td>
    </tr>


    <tr
    >
      <td
          class="text-left"
      ><b>GESAMT:</b></td>
      <td
          class="text-center"
      ><b>{{ total_weight }}</b></td>
      <td
          class="text-center"
      ><b>{{ total_qty }}</b></td>
      <td
          class="text-center"
      ><b>{{ total_num }}</b></td>
    </tr>






    </tbody>
  </v-table>

  </div>

</div>
</template>

<script>


import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)



import CardAnalyticsPurchaser from "@/components/CardAnalyticsPurchaser.vue";
import cPie from "@/components/cPie.vue";
import DialogDate from "@/components/DialogDate";

export default {
  name: "AnalyticsDisposal",

  components:{
    CardAnalyticsPurchaser,
    cPie,
    DialogDate,
    Bar,
  },

  data() {
    return {

      title: "Verkäufe",
      title2: "",

      chartData: Object,

      sync: true,
      snackbar: false,

      disposer: [],
      disposal: [],
      purchaser:[],
      purchaser_card:[],

      number_of_purchaser: 0,

      select: '',

      renderComponent: true,
      overlay: false,

      default_range_in_days: 90,

      panel: [0],

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      total_weight: 0,
      total_price: 0,
      total_qty: 0,
      total_num: 0,

      Chart: false,

      chartDataPie1: '',
      chartDataPie2: '',


      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },

  async created() {
    this.sync = true

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()


    this.sync = false

  },




  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.loadData()
    },


  },

  methods:{


    async add_purchaser(n){

      if(this.purchaser_card.length > 0){
        this.purchaser_card = []
        return
      }


      var hasMatch =false;

      for (var index = 0; index < this.purchaser_card.length; ++index) {

        var check = this.purchaser_card[index];

        if(check.id == n){
          hasMatch = true;
          break;
        }
      }

      if(hasMatch == false){
        this.purchaser_card.push({id: n, name: n})
      }


    },

    onChange(entry) {

      console.log(entry)

      this.select = entry.name

    },


    async loadData(){

      this.chartDataPie1 =  {
        labels: [],
        datasets: [
          {
            label: "in Stück",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }

      this.chartDataPie2 =  {
        labels: [],
        datasets: [
          {
            label: "in Stück",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")




      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT name, sum(weight), sum(qty), count(*) FROM stock WHERE typ='sell' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY name ORDER BY sum(weight) ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)


      this.number_of_purchaser = result1.length


      for (var i97 = 0; i97 < result1.length; i97++) {

        this.total_weight = Number(this.total_weight) + Number(result1[i97]["sum(weight)"])
        this.total_qty = Number(this.total_qty) + Number(result1[i97]["sum(qty)"])
        this.total_num = Number(this.total_num) + Number(result1[i97]["count(*)"])

        this.purchaser.push({id: result1[i97]["name"], name: result1[i97]["name"], weight: Number(result1[i97]["sum(weight)"]).toFixed(3), qty: result1[i97]["sum(qty)"], num: result1[i97]["count(*)"]})
      }


      this.total_weight = Number(this.total_weight).toFixed(3)


      // Einstellungen
      var param2 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sell_typ_text, sum(weight), sum(qty) FROM stock WHERE typ='sell' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY sell_typ_text ORDER BY sum(qty) DESC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)



      for (var i99 = 0; i99 < result2.length; i99++) {
        this.chartDataPie1["labels"].push(result2[i99]["sell_typ_text"])
        this.chartDataPie1["datasets"][0]["data"].push(result2[i99]["sum(qty)"])
      }


      for (var i98 = 0; i98 < result1.length; i98++) {

        if(i98 >5){
          break;
        }
        this.chartDataPie2["labels"].push(result1[i98]["name"])
        this.chartDataPie2["datasets"][0]["data"].push(result1[i98]["sum(qty)"])
      }


      // Remove MyComponent from the DOM
      this.Chart = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.Chart = true;

    }


  }

}
</script>

<style scoped>

</style>
