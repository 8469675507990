<template>

    <v-navigation-drawer
        v-model="drawer"
        app
        :permanent="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
        :disable-resize-watcher="false"
        clipped
        expand-on-hover
    >

      <!--
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Reifen Hüska
          </v-list-item-title>
          <v-list-item-subtitle>
            Verwaltungsprogramm
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <!--

                   mandatory

                    active-class="highlighted"
                    color="pink"
                          v-model="group"
                    -->

       <!--
        <v-list-item-group

            mandatory

            active-class="highlighted"
            color="pink"
            v-model="group"


        >
        -->

        <v-list-item
            :id="'menu_' + item.name.toString().trim()"

            v-for="item in items"
            :key="item.title"
            link
            @click="goto(item.link)"

        >

          <v-list-item-icon

          >

            <v-badge

                v-if="item.name == 'check' && num_check != 0"

                bordered
                overlap
                color="red"
            >
              <span slot="badge">{{num_check}}</span>
            <v-icon
                :id="'icon_' + item.name.toString().trim()"
            >{{ item.icon }}</v-icon>
            </v-badge>

            <v-icon
                v-else
                :id="'icon_' + item.name.toString().trim()"
            >{{ item.icon }}</v-icon>




          </v-list-item-icon>




          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <!--
                    </router-link>
          -->
        </v-list-item>

<!--
        </v-list-item-group>
-->
      </v-list>
    </v-navigation-drawer>

</template>

<script>
export default {
  name: "Sidebar",

  data () {
    return {
      // mini: true,
      drawer: false,
      group: null,

      num_check: 0,


      items: [],
      selectedColor: '#CA0931',
      defaultColor: 'rgba(0, 0, 0, 0.54)',


      right: null,
    }
  },


  created(){

    this.countCheck()

    this.items.push({ name: 'dashboard', title: ' Dashboard', icon: 'mdi-view-dashboard', link: '/Dashboard' })
    this.items.push({ name: 'customer', title: ' Kunden', icon: 'mdi-account-group', link: '/ListCustomer' })
    this.items.push({ name: 'disposal', title: ' Entsorgungen', icon: 'mdi-factory', link: '/ListDisposal' })
    this.items.push({ name: 'transport', title: ' Transporte', icon: 'mdi-truck-cargo-container', link: '/ListTransport' })
    this.items.push({ name: 'exchange', title: ' Vermittlung', icon: 'mdi-recycle', link: '/ListExchange' })
    this.items.push({ name: 'driver', title: ' Fahrer', icon: 'mdi-card-account-details', link: '/ListDriver' })
    this.items.push({ name: 'parking', title: ' Sammelstellen', icon: 'mdi-parking', link: '/ListParking' })
    this.items.push({ name: 'repeat', title: ' Folgeaufträge', icon: 'mdi-repeat-variant', link: '/ListRepeatTask' })
    this.items.push({ name: 'task', title: ' Aufträge', icon: 'mdi-truck-delivery-outline', link: '/ListTask' })
    this.items.push({ name: 'tour', title: ' Touren', icon: 'mdi-transit-detour', link: '/ListTour' })
    this.items.push({ name: 'check', title: ' Check', icon: 'mdi-flash', link: '/ListCheck' })
    this.items.push({ name: 'clearing', title: ' Abrechnung', icon: 'mdi-cash-100', link: '/ListClearing' })
    this.items.push({ name: 'analytics', title: ' Auswertung', icon: 'mdi-chart-bar', link: '/Analytics' })
    this.items.push({ name: 'stock', title: ' Lager', icon: 'mdi-abacus', link: '/ListStock' })
    this.items.push({ name: 'stock_qty', title: ' Lagerbestand', icon: 'mdi-garage-variant', link: '/ListStockQty' })
    this.items.push({ name: 'collecting', title: ' Lager Sammelliste', icon: 'mdi-clipboard-check-multiple-outline', link: '/ListCollecting' })
    this.items.push({ name: 'setting', title: ' Einstellungen', icon: 'mdi-cog-outline', link: '/Setting' })


  },


  mounted(){

    this.setSidebar();


  },

  watch:{
    $route (){
      this.setSidebar();
    },

      group (group) {
        console.log("group: " + group)
      },

  },

  computed: {

  },

  props: {

  },




  methods:{


    async countCheck(){


      // Einstellungen
      var param = {
        table: "task",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT temp_task_id FROM task WHERE (pickup_date IS NOT NULL OR pickup_date<>'') ORDER BY pickup_timestamp DESC LIMIT 100",
      }

      // GROUP BY tour_id


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      for (var i1 = 0; i1 < result.length; i1++) {

        await this.checkRating(result[i1]["temp_task_id"])

      }



    },


    async checkRating(id){

      // Einstellungen
      var param = {
        table: "task",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM task_items WHERE temp_task_id ='" + id + "' AND rating <= '" + localStorage.check_quality  + "' GROUP BY temp_task_id",
      }



      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}


      // console.log("Badge")
      // console.log(param.extension)


      var result = await this.$func.queryAPI(param, data)

      // console.log(result)

// SET GLOBAL sql_mode=(SELECT REPLACE(@@sql_mode,'ONLY_FULL_GROUP_BY',''))

/*
      console.log(param.extension)
      console.log(result)

      console.log("num")
      console.log(this.num_check)
      console.log(result.length)
*/
      this.num_check = Number(this.num_check) + result.length


     // console.log(result.length)
     // console.log(param.extension)

    },



    open() {
      this.drawer = true;
    },

    goto(path){
      if("/" + this.$route.name != path){
      this.$router.push(path)
      }
    },


    setSidebar(){

      console.log("setSidebar: " + this.$route.name)

      let i = 0;

      while (this.items[i]) {

        if(this.$route.name == this.items[i].link.toString().replace("/", "")){
          document.getElementById("menu_" + this.items[i].name).style.color = this.selectedColor;
          document.getElementById("icon_" + this.items[i].name).style.color = this.selectedColor;

          this.title = "-" + this.items[i].title
        }else{
          document.getElementById("menu_" + this.items[i].name).style.color = this.defaultColor;
          document.getElementById("icon_" + this.items[i].name).style.color = this.defaultColor;
        }

        i++;
      }


    }

  },

}
</script>

<style scoped>
.highlighted{
 color: red;
}

</style>
