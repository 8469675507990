<template>
<div
style="
margin-left: 100px;
"
>


  <v-card
      class="mx-auto"
      max-width="344"

      style="
          margin: 10px;
          "

  >
    <v-card-title>Parameter</v-card-title>

    <v-card-text>

      <v-text-field
          v-model="average_weight"
      outlined
      label="Reifen Durchschnittsgewicht in Kg"
      >

      </v-text-field>

      <v-text-field
          v-model="check_quality"
          outlined
          label="Check Qualitätsparameter 1-5"
      >

      </v-text-field>


        <v-text-field
                v-model="password_delete"
                outlined
                label="Passwort (Löschen)"
        >

        </v-text-field>

        <v-text-field
                v-model="password_setting"
                outlined
                label="Passwort (Einstellungen)"
        >

        </v-text-field>




        <v-text-field
                v-model="rating1"
                outlined
                label="Rating 1 - Gutreifenanteil in %"
        >
        </v-text-field>

        <v-text-field
                v-model="rating2"
                outlined
                label="Rating 2 - Gutreifenanteil in %"
        >
        </v-text-field>

        <v-text-field
                v-model="rating3"
                outlined
                label="Rating 3 - Gutreifenanteil in %"
        >
        </v-text-field>

        <v-text-field
                v-model="rating4"
                outlined
                label="Rating 4 - Gutreifenanteil in %"
        >
        </v-text-field>

        <v-text-field
                v-model="rating5"
                outlined
                label="Rating 5 - Gutreifenanteil in %"
        >
        </v-text-field>



    </v-card-text>

<v-card-actions>
  <v-btn
  outlined
  color="pink"
  @click="saveData"
  >Speichern</v-btn>

</v-card-actions>


  </v-card>




  <GroupList
  v-bind:title="'Artikel'"
  v-bind:table="'article'"
  v-bind:editable="true"
  v-bind:values="values_article"
  ></GroupList>

  <GroupList
      v-bind:title="'Kundengruppe'"
      v-bind:table="'group_main'"
      v-bind:editable="true"
      v-bind:values="values_gmain"
  ></GroupList>

  <GroupList
      v-bind:title="'Untergruppe'"
      v-bind:table="'group_type'"
      v-bind:editable="true"
      v-bind:values="values_gtype"
  ></GroupList>

  <GroupList
      v-bind:title="'Preisegruppe'"
      v-bind:table="'group_price'"
      v-bind:editable="true"
      v-bind:values="values_gprice"
  ></GroupList>

  <GroupList
      v-bind:title="'Ausführungsfrist'"
      v-bind:table="'group_execution_date'"
      v-bind:editable="true"
      v-bind:values="values_gexecution_date"
  ></GroupList>

  <GroupList
      v-bind:title="'Untergruppe E-Mail (für Übergabeschein)'"
      v-bind:table="'group_type_email'"
      v-bind:editable="true"
      v-bind:values="values_group_type_email"
  ></GroupList>


  <GroupList
      v-bind:title="'Intervall für Folgeaufträge'"
      v-bind:table="'group_interval'"
      v-bind:editable="true"
      v-bind:values="values_interval"
  ></GroupList>

  <GroupList
      v-bind:title="'Lager Verkauf Zuordnung'"
      v-bind:table="'group_sell_typ'"
      v-bind:editable="true"
      v-bind:values="values_sell_typ"
  ></GroupList>

  <GroupList
      v-bind:title="'PLZ Bereiche'"
      v-bind:table="'group_tour'"
      v-bind:editable="true"
      v-bind:values="values_gtour"
  ></GroupList>

  <GroupList
      v-bind:title="'Fahrer'"
      v-bind:table="'driver'"
      v-bind:editable="true"
      v-bind:values="values_driver"
  ></GroupList>

  <GroupList
      v-bind:title="'Fahrzeuge'"
      v-bind:table="'cars'"
      v-bind:editable="true"
      v-bind:values="values_cars"
  ></GroupList>

  <GroupList
      v-bind:title="'Entsorger'"
      v-bind:table="'disposer'"
      v-bind:editable="true"
      v-bind:values="values_disposer"
  ></GroupList>

  <GroupList
      v-bind:title="'Spediteure'"
      v-bind:table="'carrier'"
      v-bind:editable="true"
      v-bind:values="values_carrier"
  ></GroupList>


  <GroupList
      v-bind:title="'Benutzer'"
      v-bind:table="'user'"
      v-bind:editable="true"
      v-bind:values="values_user"
  ></GroupList>


  <GroupList
      v-bind:title="'Reifen'"
      v-bind:table="'group_tires'"
      v-bind:editable="true"
      v-bind:values="values_tires"
  ></GroupList>




<!--
<CustomSelect
    v-model="mycombo"
    v-bind:table="'carrier'"
    v-bind:field_text="'company'"
    v-bind:field_value="'id'"
    v-bind:label="'Spediteur'"
></CustomSelect>

  <v-btn
      outlined
  @click="test"
  >
Test
  </v-btn>

-->


  <!--

  System Entsorger  (Name bzgl. Abrechnung)
  Kunden Gruppe     (Preise)
  Fahrzeuge         (Kapazität)
  Entsorger         (Namen, Adresse)
  Speditionen       (Speditionen)
  Artikel           Gewicht

  -->





</div>
</template>

<script>

import GroupList from '../components/GroupList'
// import CustomSelect from '../components/CustomSelect'


export default {
  name: "Setting",
  components:{
    GroupList,
   // CustomSelect,
  },


  data () {
    return {

      mycombo: '',
      average_weight: '',
      check_quality: '',

        password_delete: '',
        password_setting: '',

        rating1: '',
        rating2: '',
        rating3: '',
        rating4: '',
        rating5: '',

      values_article: [
        {
          name: 'Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
        {
         name: 'Gewicht in kg',
         value:  'weight',
         required: true,
         typ: 'text'
        },
        {
          name: 'Entsorgungsnummer',
          value:  'wasteno',
          required: true,
          typ: 'text'
        }

      ],

      values_user: [
        {
          name: 'Benutzername',
          value:  'username',
          required: true,
          typ: 'text'
        },
        {
          name: 'Passwort',
          value:  'password',
          required: true,
          typ: 'text'
        },
      ],

      values_tires: [
        {
          name: 'Reifen Typ Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
      ],

      values_cars: [
        {
          name: 'Beschreibung',
          value:  'description',
          required: true,
          typ: 'text'
        },
        {
          name: 'Ladevolumen in Stk.',
          value:  'capacity',
          required: true,
          typ: 'text'
        },
       ],

      values_stock_typ: [
        {
          name: 'Beschreibung',
          value:  'text',
          required: true,
          typ: 'text'
        },
        {
          name: 'Wert',
          value:  'value',
          required: true,
          typ: 'text'
        },
        {
          name: 'Typ',
          value:  'typ',
          required: true,
          typ: 'text'
        },
      ],

      values_sell_typ: [
        {
          name: 'Beschreibung',
          value:  'text',
          required: true,
          typ: 'text'
        },
        {
          name: 'Wert',
          value:  'value',
          required: true,
          typ: 'text'
        },

      ],

      values_disposer: [
        {
          name: 'Name',
          value:  'company',
          required: true,
          typ: 'text'
        },

        {
          name: 'Preis pro Tonne',
          value:  'price_per_ton',
          required: true,
          typ: 'text'
        }

      ],

      values_carrier: [
        {
          name: 'Name',
          value:  'company',
          required: true,
          typ: 'text'
        },

      ],

      values_driver: [
        {
          name: 'Login Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
        {
          name: 'Signatur',
          value:  'sign_text',
          required: true,
          typ: 'text'
        },
        {
          name: 'Passwort',
          value:  'password',
          required: true,
          typ: 'text'
        },
      ],

      values_gexecution_date: [
        {
          name: 'Untergruppe',
          text:  'group_type_name',
          value:  'group_type_id',
          required: true,
          typ: 'select',
          select_table: 'group_type',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Untergruppe',
        },
        {
          name: 'Kalendertage',
          value:  'period',
          required: true,
          typ: 'text'
        },
      ],



      values_group_type_email: [
        {
          name: 'Untergruppe',
          text:  'group_type_name',
          value:  'group_type_id',
          required: true,
          typ: 'select',
          select_table: 'group_type',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Untergruppe',
        },
        {
          name: 'E-Mail',
          value:  'email',
          required: true,
          typ: 'text'
        },
      ],










      values_interval: [
        {
          name: 'Text',
          value:  'text',
          required: true,
          typ: 'text'
        },
        {
          name: 'Wert',
          value:  'value',
          required: true,
          typ: 'text'
        },
      ],



      values_gtour: [
        {
          name: 'Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
        {
          name: 'PLZ-Bereich',
          value:  'zipcode',
          required: true,
          typ: 'text'
        },
      ],

      values_gmain: [
        {
          name: 'Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
      ],

      values_gtype: [
        {
          name: 'Kundengruppe',
          text:  'group_main_name',
          value:  'group_main_id',
          typ: 'select',
          select_table: 'group_main',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Kundengruppe',
        },
        {
          name: 'Name',
          value:  'name',
          required: true,
          typ: 'text'
        },
        {
          name: 'Text',
          value:  'text',
          required: true,
          typ: 'text'
        },

      ],

      /*
      {
          name: 'Artikel',
          text:  'article_name',
          value:  'article_id',
          typ: 'select',
          select_table: 'article',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Artikel',
        },

       */

      values_gprice: [

        {
          name: 'Artikel',
          text:  'article_name',
          value:  'article_id',
          required: true,
          typ: 'select',
          select_table: 'article',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Artikel',
        },

        {
          name: 'Untergruppe',
          text:  'group_type_name',
          value:  'group_type_id',
          required: true,
          typ: 'select',
          select_table: 'group_type',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Untergruppe',
        },

        {
          name: 'Preis mit PUNKT -> .',
          value:  'price',
          typ: 'text'
        },



      ],


    }





  },

  async created() {

   var Check = prompt('Bitte Passwort eingeben', '');
    if (Check != localStorage.password_setting) {
      this.$router.push({ name: 'Dashboard' })
    }



    await this.loadData()
  },

  methods:{

    test(){
      console.log("button: " + this.mycombo)
    },


    async loadData() {
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      if(result.length == 0){
        this.average_weight = ""
      }else{
        this.average_weight = result[0]["wert"]
      }



      // Einstellungen
      param = {
        table: "parameter",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "schluessel",
        value: "check_quality",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {}

      result = await this.$func.queryAPI(param, data)

      console.log(result)

      if(result.length == 0){
        this.check_quality = "2"
      }else{
        this.check_quality = result[0]["wert"]
      }



        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "password_setting",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.password_setting = result[0]["wert"]





        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "password_delete",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.password_delete = result[0]["wert"]



        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "rating1",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.rating1 = result[0]["wert"]






        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "rating2",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.rating2 = result[0]["wert"]





        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "rating3",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.rating3 = result[0]["wert"]




        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "rating4",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.rating4 = result[0]["wert"]



        // Einstellungen
        param = {
            table: "parameter",
            statement: "select",
            type: "detail",
            where: "true",
            selector: "schluessel",
            value: "rating5",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {}

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.rating5 = result[0]["wert"]



    },


    async saveData() {
      console.log("saveData")

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "update",
        type: "",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        wert: this.average_weight
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      // Einstellungen
      param = {
        table: "parameter",
        statement: "update",
        type: "",
        where: "true",
        selector: "schluessel",
        value: "check_quality",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        wert: this.check_quality
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      localStorage.check_quality = this.check_quality



        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "password_setting",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.password_setting
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)


        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "password_delete",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.password_delete
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)


        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "rating1",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.rating1
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)




        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "rating2",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.rating2
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)





        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "rating3",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.rating3
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)


        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "rating4",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.rating4
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)


        // Einstellungen
        param = {
            table: "parameter",
            statement: "update",
            type: "",
            where: "true",
            selector: "schluessel",
            value: "rating5",
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
            wert: this.rating5
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)



        localStorage.password_setting = this.password_setting
        localStorage.password_delete = this.password_delete





    }


  }



  // values_article


}
</script>

<style scoped>

</style>
