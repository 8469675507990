<template>
  <v-app>

    <v-app-bar
      app
      clipped-left
      :color="this.header_color"
      dark
    >
      <div class="d-flex align-center">

        <v-icon
            width="60"
            style="padding-right: 20px;"
            @click="openMenu"
        >mdi-menu</v-icon>

        <!--
         @click.stop="drawer = !drawer"
        -->

        <!--
        <v-img
          alt=""
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
          @click.stop="drawer = !drawer"
        />

        -->

        <v-app-bar-title class="text-no-wrap text-left">{{this.header_title}} - {{this.title}}</v-app-bar-title>
        <!--
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
        -->

      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="#"
        target="_self"
        text
        @click="btnLogin"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>



    <Sidebar
        v-if="sidebar"
        ref="modal"
    ></Sidebar>




<v-main
    style="
    padding: 64px 0px 0px 0px;
    "
>


  <v-container

      fluid
  style="padding-left: 60px;"
  >
  <router-view />
  </v-container>

</v-main>



  </v-app>
</template>

<script>
import Vue from "vue";
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'App',

  components: {
    Sidebar,
  },

  data: () => ({
    title: '',
    header_title: Vue.prototype.$header_title,
    header_color: Vue.prototype.$header_color,
    drawer: null,
    sidebar: true,
  }),

  async created() {

    this.title = "Dashboard"

    if(this.$route.name != "Login"){

      if(localStorage.username == ""  || localStorage.username == undefined){
        this.$router.push({ name: 'Login' })
      }

    }

    if(localStorage.username == ""  || localStorage.username == undefined){
      this.sidebar = false
    }else{
      this.sidebar = true
    }

    if(localStorage.typ == "customer"){
      this.sidebar = false
    }

    this.setTitle()


      // Einstellungen
      var param = {
          table: "parameter",
          statement: "select",
          type: "detail",
          where: "true",
          selector: "schluessel",
          value: "password_setting",
          short: "false", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      localStorage.password_setting = result[0]["wert"]


      // Einstellungen
      param = {
          table: "parameter",
          statement: "select",
          type: "detail",
          where: "true",
          selector: "schluessel",
          value: "password_delete",
          short: "false", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {}

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      localStorage.password_delete = result[0]["wert"]

      Vue.prototype.$password = result[0]["wert"]

  },

  mounted() {


  },

  watch:{
    $route (){
      console.log(this.$route.name)
      this.setTitle()
    },

    drawer(){
    console.log("TEST: " + this.drawer)
    }
  },

  methods:{


    openMenu(){

      if(this.sidebar == true){
        this.$refs.modal.open();
      }

    },

    openSideBar(){
      console.log("openSideBar")
      //this.drawer = false
    },

    btnLogin(){
      this.$router.push({ name: 'Login' })
    },

    setTitle(){

      switch (this.$route.name) {
        case "Dashboard":
          this.title = "Dashboard"

          if(localStorage.username == ""  || localStorage.username == undefined){
            this.sidebar = false
          }else{
            this.sidebar = true
          }

          break;

        case "ListCustomer":
          this.title = "Kunden"
          break;

        case "ListRepeatTask":
          this.title = "Folgeaufträge"
          break;

        case "ListTask":
          this.title = "Aufträge"
          break;

        case "ListTour":
          this.title = "Touren"
          break;

        case "ListDriver":
          this.title = "Fahrer"
          break;

        case "ListTransport":
          this.title = "Transporte"
          break;

        case "ListDisposal":
          this.title = "Entsorgungen"
          break;

        case "ListClearing":
          this.title = "Abrechnung"
          break;

          case "ListParking":
              this.title = "Sammelstellen"
              break;

        case "ListCheck":
          this.title = "Check"
          break;

        case "ListStock":
          this.title = "Lager"
          break;

          case "ListStockQty":
              this.title = "Lagerbestand"
              break;

        case "ListCollecting":
          this.title = "Lager Sammelliste"
          break;

        case "ListExchange":
          this.title = "Vermittlung"
          break;

        case "Analytics":
          this.title = "Auswertung"
          break;

        case "Setting":
          this.title = "Einstellungen"
          break;

        case "Login":
          this.title = "Login"
          this.sidebar = false
          break;

      }
    }



  }



};
</script>

<style>
.v-app-bar-title__content{
  width: 380px !important;
}

.v-app-bar-title__placeholder{
  visibility: visible !important;
}


.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


</style>
