<template>

  <div>


  <v-card
      class="mx-auto"
      width="450"
      style="margin: 10px;"
  >
    <v-card-text>
      <div>NICHT ABGESCHLOSSENE TOUREN</div>


      <v-card

          v-for="item in List"
          :key="item.id"
          class="mx-auto"
          width="450"
          style="
          background-color: rgba(233,30,99,0.22);
          margin: 10px;"

      >

<div
style="padding: 30px;"
>
        <div>Tour Nr.: {{item.id}}</div>
        <div>Tour Datum: {{item.planed_date}}</div>
        <div>Fahrer: {{item.driver_name}}</div>
        <div>Offene Aufträge: <b>{{item.task_qty}}</b></div>
        <div>Offene Reifen: <b>{{item.qty}}</b></div>
        <div>Bemerkung:</div>
        <div><b>{{item.note}}</b></div>

</div>


        <cTimeLine
            v-bind:tour_id="item.id"
        ></cTimeLine>


        <v-card-actions

        >

          <v-col

              class="text-left"
          >
          <v-btn
              text
              color="pink accent-3"
              @click="showTour(item.id)"
          >
            Details
          </v-btn>
            </v-col>



              <v-col
                  sm="2"
                  md="2"
                  class="text-left"
              >
            <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id)">mdi-delete-outline</v-icon>
                </v-col>

        </v-card-actions>


      </v-card>






    </v-card-text>


  </v-card>


  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >

      <!--
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  Open Dialog
                </v-btn>
              </template>
      -->



      <v-card>
        <v-card-title>
          <span class="text-h5">Wirklich Löschen</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              Tour wird gelöscht und nicht abgeschlossene Aufträge werden freigegeben
            </v-row>

            <v-text-field
                v-model="dialog_password"
                type="password"
                placeholder="Passwort"
            ></v-text-field>

          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn

              text
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red"
              text
              @click="deleteTour(dialog_id)"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>



    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>




  </div>



</template>

<script>

import Vue from "vue";
import cTimeLine from '../components/cTimeLine'

export default {
  name: "CardPastTours",

  components: {
    cTimeLine,
  },

  data () {
    return {

      List: [],

      dialog: false,
      dialog_id: "",
      dialog_password: "",

      snackbar_title: "",
      snackbar_color: "",
      snackbar: "",

      timeout: 4000,

    }
  },

  async created() {

    await this.loadData()

  },


  methods:{

    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "tour",
        statement: "custom",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM tour WHERE pickup_date IS NULL AND planed_date_timestamp < '" + this.$moment(Date.now()).format('YYYY-MM-DD') + "' ORDER BY planed_date_timestamp DESC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log("Nicht Abgeschlossene Touren")
      console.log(result)

      this.List = result


      for (var i1 = 0; i1 < result.length; i1++) {
        await this.checkTour(result[i1]["id"])
      }





      /*  Fahren werden upgedated qty_tours, qty_articles   */

      // Einstellungen
      param = {
        table: "driver",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      // console.log("driver->List")
      // console.log(result)


      for (var i2 = 0; i2 < result.length; i2++) {
        if(result[i2]["id"] != "") {
          await this.updateDriver(result[i2]["id"])
        }
      }






    },


    async checkTour(id){
      console.log("checkTour: " + id)

      // Einstellungen
      var param = {
        table: "task",
        statement: "custom",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT count(*), sum(order_qty) FROM task WHERE tour_id='" + id + "' AND pickup_date IS NULL",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // console.log("checkTour")
      // console.log(result)


      var task_qty = result[0]["count(*)"]
      var qty = result[0]["sum(order_qty)"]


      // Einstellungen
      param = {
        table: "tour",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      task_qty: task_qty,
      qty: qty,
      }

      result = await this.$func.queryAPI(param, data)

    },



    async updateDriver(id){
      // console.log("updateDriver")
      // console.log(id)

      // Einstellungen
      var param = {
        table: "tour",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT count(*), sum(qty), sum(task_qty) FROM tour WHERE driver_id='" + id + "' AND pickup_date IS NULL",
      }

      // console.log(param.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
/*
      var result = await this.$func.queryAPI(param, data)
      console.log("tour->driver info")
      console.log(result)
*/


      // Einstellungen
      param = {
        table: "driver",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        qty_tours: result[0]["count(*)"],
        qty_task: result[0]["sum(task_qty)"],
        qty_articles: result[0]["sum(qty)"],
      }

     await this.$func.queryAPI(param, data)
/*
      result = await this.$func.queryAPI(param, data)
      console.log("driver->update")
      console.log(result)
*/



    },





    showTour(id){
      console.log(id)

      this.$router.push({ path: 'FormTour/' + id })


    },


    async showDeleteDialog(id){
      this.dialog_id = id
      this.dialog = true
    },


    async deleteTour(id){
      console.log("deleteTour" + id)

      if(this.dialog_password != Vue.prototype.$password){

        this.snackbar_title = "Password Falsch!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      // Einstellungen
      var param = {
        table: "tour",
        statement: "update",
        type: "",
        where: "",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        pickup_date: this.$moment(Date.now()).format("DD.MM.YYYY"),
      }


      await this.$func.queryAPI(param, data)

/*
      var result = await this.$func.queryAPI(param, data)
      console.log (result)
*/


      // Einstellungen
      param = {
        table: "task",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "UPDATE task SET tour_id='' WHERE tour_id='" + id + "' AND pickup_date IS NULL",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
       data = {
      }

      await this.$func.queryAPI(param, data)
      // result = await this.$func.queryAPI(param, data)
      // console.log (result)


    }

  }


}
</script>

<style scoped>

</style>
