import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import store from './store'

import VueAxios from './plugins/axios'
import {func} from './plugins/func'
import Moment from 'vue-moment'
Vue.use(Moment);

import VueJsonToCsv from 'vue-json-to-csv'
Vue.use(VueJsonToCsv);

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDqH0F1VXO8Qt0V_4BXeb_RqiO9cwWidQA', // ALL
    libraries: 'places',
  },

});

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
  defaultCountryCode: 'DE', // e.g. 'CA'
  defaultLanguage:    'de', // e.g. 'en'
  defaultMode:        'address', // or 'lat-lng'
  googleMapsApiKey:   'AIzaSyDQfSidoCnLMO7sEv5pyP55wkBKDKK8_OU' // GEOCODING API
});



import {GmapCustomMarker} from 'vue2-gmap-custom-marker';
Vue.component('GmapCustomMarker', GmapCustomMarker);


// Using Cluster requires marker-clusterer-plus to be installed.
import GmapCluster from "gmap-vue/dist/components/cluster";

// Note: the name "cluster" below is the one to use in the template tags
Vue.component("cluster", GmapCluster);

Vue.prototype.$func = func

// Globale Variablen
// Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'

/*
Vue.prototype.$header_title = 'Reifen Hüska'
Vue.prototype.$header_color = '#383083'
Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
Vue.prototype.$api_hostname = window.location.host

Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'
*/


if (window.location.hostname.includes("reifen-hueska.de") || window.location.hostname.includes("app-ionos.space")){
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
  Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
  Vue.prototype.$api_hostname = window.location.host


}else if (window.location.hostname.includes("reifen-muka.de")) {
  Vue.prototype.$header_title = 'Reifen Muka'
  Vue.prototype.$header_color = '#0826e3'
  Vue.prototype.$header_logo = '@/assets/images/logo_muka.png'

  Vue.prototype.$api_endpoint = 'https://www.reifen-muka.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://www.reifen-muka.de/api/create_pdf.php'
  Vue.prototype.$api_upload = 'https://www.reifen-muka.de/api/upload/'
  Vue.prototype.$api_hostname = window.location.host


}else if (window.location.hostname.includes("localhost")) {
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'


  Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
  Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
  Vue.prototype.$api_hostname = window.location.host

}

Vue.prototype.$password = "123qweasd"


// Axios
Vue.use(VueAxios);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
